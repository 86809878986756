<template>
  <v-form
    ref="form"
    lazy-validation
  >
    <v-card>
      <v-card-title v-if="title">
        <span class="text-h3">{{ $t(title) }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="options.name"
                dense
                :disabled="readOnly"
                :label="$t('name_*')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              v-if="authUser.isAdmin"
              cols="12"
              sm="6"
            >
              <v-autocomplete
                v-model="options.product"
                dense
                :disabled="type == 'edit'"
                :items="['oem', 'cso']"
                item-text="name"
                item-value="id"
                :label="$t('select_product_*')"
                :rules="[rules.required]"
                @change="tenantList"
              />
            </v-col>
            <v-col
              v-if="authUser.isAdmin && options.product"
              cols="6"
              sm="6"
            >
              <v-autocomplete
                v-model="options.tenant_id"
                dense
                :loading="tenantLoading"
                :items="options.product
                  ? options.product == 'oem'
                    ? tenantsOEM
                    : tenantsCSO
                  : []"
                :disabled="type == 'edit'"
                item-text="name"
                item-value="id"
                :label="$t('select_tenant_*')"
                :rules="[rules.required]"
              />
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="options.pricing_mode"
                dense
                :disabled="readOnly"
                :items="descOptions"
                item-value="id"
                item-text="name"
                :label="$t('pricing_mode') + ' *'"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="options.currency"
                dense
                :disabled="readOnly"
                :items="currencyOptions"
                item-value="id"
                item-text="name"
                :label="$t('currency_*')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="options.status"
                dense
                :disabled="readOnly"
                :items="statusOptions"
                item-value="id"
                item-text="name"
                :label="$t('status_*')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="d-flex align-center justify-space-between">
                <div class="text-h3">
                  {{ $t("price_components") }}
                </div>
                <v-btn
                  v-if="options.pricing_mode == 'kwh'"
                  color="primary"
                  small
                  class="addButton"
                  @click="addPriceComponent"
                >
                  +
                </v-btn>
              </div>
            </v-col>
            <div v-if="options.pricing_mode == 'kwh'">
              <span class="error-message all-error-text"> {{ errorMessage }} </span>
              <v-row
                v-for="(component, index) in priceComponentsKWH"
                :key="index"
                class="d-flex align-center mb-4 priceRow"
                :class="{ 'error-row': hasError(index) }"
                no-gutters
              >
                <v-col
                  cols="5"
                  class="px-2"
                >
                  <div class="d-flex gap timeField">
                    <v-text-field
                      v-model="component.start_time"
                      dense
                      :disabled="readOnly"
                      type="time"
                      name="start_time"
                      min="09:00"
                      max="18:00"
                      label="Start Time"
                      :rules="[rules.required]"
                      hint="Select start time"
                      @blur="validateRows"
                    />
                    <v-text-field
                      v-model="component.end_time"
                      dense
                      label="End Time"
                      :disabled="readOnly"
                      type="time"
                      min="12:25"
                      :rules="[timeWindowValidation(component)]"
                      @blur="validateRows"
                    />
                    <div
                      v-if="component.start_time || component.end_time"
                      class="timeWindow-clear closeTime"
                      @click="timeWindowClear(component)"
                    >
                      X
                    </div>
                  </div>
                </v-col>
                <v-col
                  cols="4"
                  class="px-2"
                >
                  <v-text-field
                    v-model="component.price"
                    dense
                    :disabled="readOnly"
                    :label="$t('price_*')"
                    :rules="[rules.required, rules.float(2)]"
                  />
                </v-col>
                <v-col
                  cols="3"
                  class="px-2"
                >
                  <v-text-field
                    v-model="component.tax"
                    dense
                    :disabled="readOnly"
                    :label="$t('tax_*')"
                    :rules="[rules.required, rules.float(2)]"
                  />
                </v-col>
                <v-col
                  v-if="index != 0"
                  cols="3"
                  class="px-2 deleteButton"
                >
                  <div
                    class="action-btn"
                    @click="removePriceComponent(index)"
                  >
                    <v-icon small>
                      mdi-delete
                    </v-icon>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div v-if="options.pricing_mode == 'time_duration'">
              <v-row
                v-for="(componentTime, index) in priceComponentsTime"
                :key="index"
                class="d-flex align-center"
                no-gutters
              >
                <v-col
                  v-if="options.pricing_mode == 'time_duration'"
                  cols="4"
                  class="px-2"
                >
                  <v-select
                    v-model="componentTime.duration"
                    dense
                    :disabled="readOnly || options.pricing_mode == 'kwh'"
                    :items="durationOptions"
                    item-value="id"
                    :rules="[rules.required]"
                    item-text="name"
                    :label="options.pricing_mode == 'kwh'
                      ? $t('duration')
                      : $t('duration_*')
                    "
                  />
                </v-col>
                <v-col
                  cols="4"
                  class="px-2"
                >
                  <v-text-field
                    v-model="componentTime.price"
                    dense
                    :disabled="readOnly"
                    :label="$t('price_*')"
                    :rules="[rules.required, rules.float(2)]"
                  />
                </v-col>
                <v-col
                  cols="3"
                  class="px-2"
                >
                  <v-text-field
                    v-model="componentTime.tax"
                    dense
                    :disabled="readOnly"
                    :label="$t('tax_*')"
                    :rules="[rules.required, rules.float(2)]"
                  />
                </v-col>
              </v-row>
            </div>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <small v-if="!readOnly">{{ $t("indicates_required_field") }}</small>
        <v-spacer />
        <v-btn
          color="red darken-1"
          text
          @click="closeForm()"
        >
          Close
        </v-btn>
        <v-btn
          v-if="!readOnly"
          color="primary"
          text
          :loading="loading"
          @click="saveItem"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import { validationRules } from "@/mixins/validationRules";
import { mapGetters } from "vuex";

export default {
  mixins: [validationRules],
  props: {
    type: {
      type: String,
      default() {
        return {
          time: null,
        };
      },
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    readOnly: {
      type: Boolean,
      default() {
        return false;
      },
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      errorMessage: "",
      options: {},
      tenantOEM: [],
      isOpenTime: false,
      isCloseTime: false,
      priceComponentsKWH: [
        {
          start_time: "00:00",
          end_time: "23:59",
          price: null,
          tax: null,
          duration: null,
        },
      ],
      priceComponentsTime: [
        {
          start_time: null,
          end_time: null,
          price: null,
          tax: null,
          duration: null,
        },
      ],
      descOptions: [
        {
          id: "kwh",
          name: "kWh",
        },
        {
          id: "time_duration",
          name: "TIME DURATION",
        },
      ],
      statusOptions: [
        {
          id: "1",
          name: "active",
        },
        {
          id: "0",
          name: "inactive",
        },
      ],

      durationOptions: [
        // {
        //   id: 15,
        //   name: "15-MINUTES",
        // },
        {
          id: 30,
          name: "30-MINUTES",
        },
        // {
        //   id: 45,
        //   name: "45-MINUTES",
        // },
        // {
        //   id: 60,
        //   name: "60-MINUTES",
        // },
      ],
      currencyOptions: [
        {
          id: "PKR",
          name: "PKR",
        },
        {
          id: "USD",
          name: "USD",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      tenantLoading: "tenantsManagement/getIsLoading",
      tenantsList: "tenantsManagement/getTenantOptions",
      tenantsOEM: "tenantsManagement/getTenantsOEM",
      tenantsCSO: "tenantsManagement/getTenantsCSO",
    }),
    title() {
      if (this.readOnly) {
        return "view";
      }
      if (this.type == "create") {
        return "add_new";
      } else if (this.type == "edit") {
        return "edit";
      } else {
        return "";
      }
    },
  },
  watch: {
    // Watch for changes in the item prop, ensuring price components are updated on edit
    // type: {
    //   handler() {
    //     if (this.type === "edit") {
    //       this.updatePriceComponents();
    //     }
    //   },
    //   deep: true,  // Deep watch to ensure it reacts to any changes in the item object
    // },

  },
  async mounted() {

    if (!this.authUser.isAdmin) {
      this.options.tenant_id = this.authUser.tenant_id;
    }
    if (this.type == "create") {
      this.options.currency = "PKR";
    }

    if (this.type == "edit") {
      this.options = { ...this.item };
      if (this.options.pricing_mode === "kwh") {
        this.priceComponentsKWH = [...(this.options.rate_components)];
      } else if (this.options.pricing_mode === "time_duration") {
        this.priceComponentsTime = [...(this.options.rate_components)];
      }
    }
  },
  methods: {
    updatePriceComponents() {
      // Reset the options and initialize price components based on the item data
      this.options = { ...this.item };
      // Reinitialize price components based on pricing mode
      if (this.options.pricing_mode === "kwh") {
        this.priceComponentsKWH = [...(this.options.rate_components || [])];
      } else if (this.options.pricing_mode === "time_duration") {
        this.priceComponentsTime = [...(this.options.rate_components || [])];
      }

    },
    validateRows() {
      this.errorMessage = ""; // Clear existing errors

      if (this.priceComponentsKWH.length === 0) {
        this.errorMessage = "Please define at least one time period.";
        return;
      }

      const times = [];

      for (const row of this.priceComponentsKWH) {
        const { start_time, end_time } = row;

        // Check if startTime and end_time are defined
        if (!start_time || !end_time) {
          this.errorMessage =
            "All rows must have a start time and an end time.";
          return;
        }

        // Parse time values
        let start = this.timeToMinutes(start_time);
        let end = this.timeToMinutes(end_time);

        // Adjust for midnight
        if (end === 0) {
          end = 24 * 60; // Treat midnight as 24:00
        }

        // Check if start time is before end time
        if (start >= end) {
          this.errorMessage = "Start time must be before end time.";
          return;
        }

        // Collect time intervals
        times.push({ start, end });
      }

      // Check for overlapping intervals
      times.sort((a, b) => a.start - b.start);
      for (let i = 1; i < times.length; i++) {
        if (times[i].start < times[i - 1].end) {
          this.errorMessage = "Time periods cannot overlap.";
          return;
        }
      }

      // Check if the total time covers 24 hours
      const totalMinutes = times.reduce(
        (sum, t) => sum + (t.end - t.start),
        0
      );
      if (totalMinutes !== 1439) {
        this.errorMessage = "The total time periods must cover 24 hours.";
        return;
      }
      this.errorMessage = "";
    },

    timeToMinutes(time) {
      const [hours, minutes] = time.split(":").map(Number);
      return hours * 60 + minutes;
    },
    addPriceComponent() {
      this.priceComponentsKWH.push({
        start_time: null,
        end_time: null,
        price: null,
        tax: null,
        duration: null,
      });
    },
    removePriceComponent(index) {
      this.priceComponentsKWH.splice(index, 1);
      this.validateRows()
    },
    formatTime(time24) {
      if (time24) {
        // Split the time string into hours and minutes
        const [hours24, minutes] = time24.split(":").map(Number);
        // Determine AM/PM and convert hours
        const isPM = hours24 >= 12;
        const hours12 = hours24 % 12 || 12; // Convert to 12-hour format, 0 hour should be 12
        const period = isPM ? "PM" : "AM";
        // Format the minutes to always be two digits
        const formattedMinutes = minutes.toString().padStart(2, "0");

        // Return the formatted time string
        return `${hours12}:${formattedMinutes} ${period}`;
      } else return "";
    },
    getRowError(index) {
      return this.priceComponentsKWH[index]?.error || null;
    },
    hasError(index) {
      return !!this.getRowError(index);
    },

    timeWindowClear(row) {
      row.start_time = null;
      row.end_time = null;
    },
    timeWindowValidation(val) {
      if (val?.start_time) {
        const errMsg = `Greater than ${this.formatTime(val.start_time)}`;
        if (val?.end_time) {
          const [stHrs, stMin] = val.start_time.split(":").map(Number);
          const [enHrs, enMin] = val.end_time.split(":").map(Number);

          if (stHrs <= enHrs) {
            //Hours
            if (stHrs == enHrs) {
              // if hours equal and min greater
              if (stMin < enMin) {
                return true;
              } else {
                return errMsg;
              }
            }
            return true;
          } else {
            return errMsg;
          }
        } else return "Required";
      }
      return true;
    },
    tenantList() {
      if (this.product == 'oem') {
        this.tenantOEM = this.tenantsList.filter((r) => r.product == "oem");
      } else {
        this.tenantOEM = this.tenantsList.filter((r) => r.product == "cso");

      }
    },
    saveItem() {

      if (this.$refs.form.validate() && this.errorMessage == "") {
        if (this.options.pricing_mode === "kwh") {
          this.options.rate_components = [...this.priceComponentsKWH];
        } else if (this.options.pricing_mode === "time_duration") {
          this.options.rate_components = [...this.priceComponentsTime];
        }
        this.$emit("onsave", this.options);
      }
    },
    closeForm() {
      // this.$refs.form.reset();
      this.$emit("onclose");
    },

  },
};
</script>
<style lang="sass" scoped>
.action-btn
    cursor: pointer
    &:hover
        i
            color: #20a390
.error-row
  background-color: #fdecea

.error-message
  color: red
  font-size: 0.9em

.all-error-text
  margin-bottom: 1.5rem !important
  display: block
  margin-left: 0.65rem

.addButton
  :deep .v-btn__content
    font-size: 1.25rem !important
.priceRow
  position: relative

.deleteButton
  .action-btn
    position: absolute
    right: -53px
    top: 1px
    background: #FDEDE8
    border-radius: 6px
    padding: 0.3em 1rem
    i
      color: #FA896B

.timeField
  :deep .v-input:first-child
    margin-right: 0.75rem

.closeTime
  margin-top: 0.7rem
  cursor: pointer
</style>